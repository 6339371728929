const sdkChatFB = (idChatFB: string, fbId: string) => {
  const newWindow = window as any;
  const chatbox = document.getElementById('fb-customer-chat');
  if (chatbox) {
    chatbox.setAttribute('page_id', idChatFB); // "113717061743659"
    chatbox.setAttribute('attribution', 'biz_inbox');
  }

  newWindow.fbAsyncInit = function () {
    try {
      newWindow?.FB.init({
        appId: fbId,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v18.0'
      });
    } catch {
      // Throw error
    }
  };

  (function (d, s, id) {
    try {
      const js: any = d.createElement(s);
      const fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js.id = id;
      // https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js
      js.src = 'https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    } catch {
      // Throw error
    }
  }(document, 'script', 'facebook-jssdk'));
};

export default sdkChatFB;
