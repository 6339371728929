export const BASE_URL = process.env.REACT_APP_BASE_URL;

function mapModifiers(
  baseClassName: string,
  ...modifiers: (string | string[] | false | undefined)[]
): string {
  return modifiers
    .reduce<string[]>((acc, m) => (!m ? acc : [...acc, ...(typeof m === 'string' ? [m] : m)]), [])
    .map((m) => `-${m}`)
    .reduce<string>(
      (classNames, suffix) => `${classNames} ${baseClassName}${suffix}`,
      baseClassName,
    );
}

export const baseURL = (src?: string): string => `${BASE_URL || ''}${src || ''}`;

export const mappingURLToExternal = (path?: string) => path ? new URL(path, BASE_URL || '').href : '';

export function downloadImage(dataURL: string, filename: string) {
  const link = document.createElement('a');
  link.href = dataURL;
  link.target = '_blank';
  link.download = filename;
  document.body.appendChild(link);
  link.click();
}

export const checkExternalUrl = (str?: string) => {
  if (!str) return false;
  const tareaRegex = /^(http|https|tel)/;
  return tareaRegex.test(String(str).toLowerCase());
};

export const handleScrollCenter = (
  ref: React.RefObject<HTMLDivElement | null>,
  classNameEleActive: string,
) => {
  const eleScroll = ref.current;
  const eleActive = eleScroll && eleScroll.querySelector(classNameEleActive);
  if (!eleActive || !eleScroll) return;
  // get width element scroll
  const widthEleScroll = eleScroll.getBoundingClientRect().width;
  // get distance element scroll compared to y window
  const xEleScroll = eleScroll.getBoundingClientRect().x;
  // get width element active
  const widthEleActive = eleActive.getBoundingClientRect().width;
  // get distance element active compared to y window
  const xEleActive = eleActive.getBoundingClientRect().x;
  // get position sroll bar
  const positionScroll = eleScroll.scrollLeft;
  const scrollX = xEleActive - xEleScroll
    + widthEleActive / 2 + positionScroll - widthEleScroll / 2;
  eleScroll.scroll({
    left: scrollX,
    behavior: 'smooth',
  });
};

/*!
 * Scroll down to next block element
 */
export function scrollDownNextSectionById(id?: string) {
  const ref = document.getElementById(id || '');
  if (ref) {
    window.scrollTo(
      { behavior: 'smooth', top: ref.offsetTop - 68 },
    ); // Minus header height
  }
}

export default mapModifiers;
