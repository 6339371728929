import { useEffect } from 'react';

import { useAppSelector } from 'store/hooks';
import sdkChatFB from 'utils/sdkChatFB';

export default function useFB() {
  const fbChatId = useAppSelector((state) => state.system.systemData?.fbChatId);
  const fbId = useAppSelector((state) => state.system.systemData?.fbId);

  useEffect(() => {
    if (fbChatId && fbId) {
      setTimeout(() => {
        sdkChatFB(fbChatId, fbId);
      }, 5000);
    }
  }, [fbChatId, fbId]);
}
