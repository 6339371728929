import { ItemCircleSkill } from "components/molecules/PieceSkill";

export const LOCAL_STORAGE = {
  ACCESS_TOKEN: 'PORTFOLIO_AccessToken',
  REFRESH_TOKEN: 'PORTFOLIO_RefreshToken',
  LANGUAGE: 'PORTFOLIO_Language',
};

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const circleSkill: ItemCircleSkill[] = [
  {
    id: 1,
    backgroundGradients: [{
      color: '#C76494',
      offset: '50%'
    },
    {
      color: '#613148',
      offset: '100%'
    }],
    heading: 'SCSS',
    logo: 'https://picsum.photos/42',
    description: 'Sass is the most mature, stable, and powerful professional grade CSS extension language in the world.'
  },
  {
    id: 2,
    backgroundGradients: [{
      color: '#41873F',
      offset: '50%'
    },
    {
      color: '#10210F',
      offset: '100%'
    }],
    heading: 'NodeJS',
    logo: 'https://picsum.photos/42',
    description: 'Node.js® is a free, open-source, cross-platform JavaScript runtime environment that lets developers create servers, web apps, command line tools and scripts.'
  },
  {
    id: 3,
    backgroundGradients: [{
      color: '#0074D8',
      offset: '50%'
    },
    {
      color: '#003D72',
      offset: '100%'
    }],
    heading: 'Typescript',
    logo: 'https://picsum.photos/42',
    description: 'TypeScript is a strongly typed programming language that builds on JavaScript, giving you better tooling at any scale.'
  },
  {
    id: 4,
    backgroundGradients: [{
      color: '#F1BC19',
      offset: '0%'
    },
    {
      color: '#F1BC19',
      offset: '100%'
    }],
    heading: 'Javascript',
    logo: 'https://picsum.photos/42',
    description: `
    JavaScript is the world's most popular programming language.
    <br />
    JavaScript is the programming language of the Web.
    <br />
    JavaScript is easy to learn.
    <br />
    This tutorial will teach you JavaScript from basic to advanced.
    `
  },
  {
    id: 5,
    backgroundGradients: [{
      color: '#0074D8',
      offset: '0%'
    },
    {
      color: '#0074D8',
      offset: '100%'
    }],
    heading: 'Typescript',
    logo: 'https://picsum.photos/42',
    description: 'TypeScript is a strongly typed programming language that builds on JavaScript, giving you better tooling at any scale.'
  },
  {
    id: 6,
    backgroundGradients: [{
      color: '#0074D8',
      offset: '0%'
    },
    {
      color: '#0074D8',
      offset: '100%'
    }],
    heading: 'Typescript',
    logo: 'https://picsum.photos/42',
    description: 'TypeScript is a strongly typed programming language that builds on JavaScript, giving you better tooling at any scale.'
  },
  {
    id: 7,
    backgroundGradients: [{
      color: '#0074D8',
      offset: '0%'
    },
    {
      color: '#0074D8',
      offset: '100%'
    }],
    heading: 'Typescript',
    logo: 'https://picsum.photos/42',
    description: 'TypeScript is a strongly typed programming language that builds on JavaScript, giving you better tooling at any scale.'
  },
  {
    id: 8,
    backgroundGradients: [{
      color: '#0074D8',
      offset: '0%'
    },
    {
      color: '#0074D8',
      offset: '100%'
    }],
    heading: 'Typescript',
    logo: 'https://picsum.photos/42',
    description: 'TypeScript is a strongly typed programming language that builds on JavaScript, giving you better tooling at any scale.'
  },
  {
    id: 9,
    backgroundGradients: [{
      color: '#0074D8',
      offset: '0%'
    },
    {
      color: '#0074D8',
      offset: '100%'
    }],
    heading: 'Typescript',
    logo: 'https://picsum.photos/42',
    description: 'TypeScript is a strongly typed programming language that builds on JavaScript, giving you better tooling at any scale.'
  },
  {
    id: 10,
    backgroundGradients: [{
      color: 'rgb(41,70,96)',
      offset: '50%'
    },
    {
      color: 'rgb(85,144,198)',
      offset: '100%'
    }],
    heading: 'Typescript',
    logo: 'https://picsum.photos/42',
    description: 'TypeScript is a strongly typed programming language that builds on JavaScript, giving you better tooling at any scale.'
  },
];
