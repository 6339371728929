import React from 'react';

import address from 'assets/icons/ic_address.svg';
import mail from 'assets/icons/ic_mail.svg';
import phone from 'assets/icons/ic_phone.svg';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import FollowMe from 'components/molecules/FollowMe';
import InformationContact from 'components/molecules/InformationContact';
import Container from 'components/organisms/Container';
import { Col, Row } from 'react-bootstrap';
import { useAppSelector } from 'store/hooks';
interface FooterProps {
}

const Footer: React.FC<FooterProps> = () => {
  const footer = useAppSelector((state) => state.system.headerFooter?.footer);
  const socials = [
    {
      icon: address,
      title: 'Address',
      content: footer?.address
    },
    {
      icon: phone,
      title: 'Phone',
      content: footer?.phone
    },
    {
      icon: mail,
      title: 'Email',
      content: footer?.email
    }
  ]

  return (
    <footer className="t-footer">
      <Container>
        <Row>
          <Col xl={4} lg={4} md={3}>
            <div className="t-footer_logo">
              <Image
                src={footer?.logo}
                alt="HNT"
                ratio="1x1"
                size={{ width: '60', height: '60' }}
                objectFit="contain"
                loading="lazy"
              />
            </div>
          </Col>
          <Col xl={4} lg={4} md={5} className="t-footer_info">
            <Text customClass="mb-12" modifiers={['600', 'primary', '16x21']}>Contact Info</Text>
            <div className="t-footer_infoContact">
              {
                socials.map((social, index) => (
                  <div key={index} className="t-footer_infoContact_item">
                    <InformationContact
                      icon={social.icon}
                      title={social.title}
                      content={social.content}
                    />
                  </div>
                ))
              }
            </div>
          </Col>
          {/* <Col xl={3}>
            <Text customClass="mb-12" modifiers={['600', 'primary', '16x21']}>Services</Text>
            <div className="t-footer_socials">
              {
                socials.map((social, index) => (
                  <div key={index} className="t-footer_socials_item">
                    <InformationContact
                      icon={social.icon}
                      title={social.title}
                      content={social.content}
                    />
                  </div>
                ))
              }
            </div>
          </Col> */}
          <Col xl={4} lg={4} md={4} className="t-footer_getInTouch">
            <Text customClass="mb-12" modifiers={['600', 'primary', '16x21']}>Get In Touch</Text>
            <div>
              {
                footer?.messageGetInTouch && (
                  <Text modifiers={['400', 'davyGrey', '14x21', 'italic']} content={footer?.messageGetInTouch} />
                )
              }
              <div className="t-footer_socials">
                <FollowMe isFooter />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
