import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import React from 'react';
export interface InformationContactProps {
  icon: string;
  title: string;
  content?: string;
}

const InformationContact: React.FC<InformationContactProps> = ({
  icon,
  title,
  content
}) => (
  <div className="m-informationContact">
    <div className="m-informationContact_icon">
      <Image
        alt={title}
        src={icon}
        loading="lazy"
        ratio="1x1"
        objectFit="contain"
        size={{
          width: '36',
          height: '36'
        }}
      />
    </div>
    <div className="m-informationContact_content">
      <Text modifiers={['14x21', '500', 'gray1']}>{content}</Text>
      {/* <div className="m-informationContact_title">
        <Text modifiers={['14x21', '600', 'gray']}>{title}</Text>
      </div>
      <div>
        <Text modifiers={['14x21', '500', 'gray1']}>{content}</Text>
      </div> */}
    </div>
  </div >
);

export default InformationContact;
