import React from 'react';

import mapModifiers from 'utils/functions';

export type BackgroundColor = 'bg-white' | 'bg-red' | 'bg-sonicSilver-notify';
type BackgroundBoxShadow = 'shadow-white';
type Padding = 'pd-2x4';
type FontSize = 'fs-14';
type variant = 'outline';

export type GeneralButtonModify = (
  | GeneralTextStyle
  | Padding
  | BackgroundColor
  | BackgroundBoxShadow
  | FontSize
  | variant
)[]

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  modifiers?: GeneralButtonModify;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  modifiers,
  loading,
  disabled,
  type = 'button',
  ...props
}) => (
  <label className="a-button_wrap">
    <button
      {...props}
      className={mapModifiers(
        'a-button',
        modifiers,
        loading ? 'loading' : '',
      )}
      // onClick={handleClick}
      type={type}
      disabled={disabled || loading}
    >
      {children}
    </button>
  </label>
);

Button.defaultProps = {
  modifiers: ['uppercase', '700', 'white'],
  loading: false,
  disabled: false,
};

export default Button;
