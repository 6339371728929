import React from 'react';

import mapModifiers from 'utils/functions';

export interface LoadingProps {
  variant?: 'fullScreen' | 'default';
  size?: '16' | '32' | '48' | '64'
}
const Loading: React.FC<LoadingProps> = ({ variant, size }) => (
  <div className={mapModifiers('a-loading', variant)}>
    <div className={mapModifiers('a-loading_spinner', size)}>
      <div className="a-loading_spinner-inner one" />
      <div className="a-loading_spinner-inner two" />
      <div className="a-loading_spinner-inner three" />
    </div>
  </div>
);

Loading.defaultProps = {
  variant: 'default',
  size: '64',
};

export default Loading;
