import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getErrorPagesService, getHeaderFooterService, getSystemService } from 'services/system';
import { ErrorPagesType, HeaderFooter, SystemTypes } from 'services/system/types';

interface SystemState {
  loadingInit: boolean;
  systemData?: SystemTypes;
  headerFooter?: HeaderFooter;
  errorPages?: ErrorPagesType;
}

const initialState: SystemState = {
  loadingInit: true,
};

export const getSystemGeneralAsync = createAsyncThunk<SystemTypes>(
  'systemReducer/getSystemGeneralAsync',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getSystemService();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getHeaderFooterAsync = createAsyncThunk<HeaderFooter>(
  'systemReducer/getHeaderFooterAsync',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getHeaderFooterService();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getErrorPagesAsync = createAsyncThunk<ErrorPagesType>(
  'systemReducer/getErrorPagesAsync',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getErrorPagesService();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const systemSlice = createSlice({
  name: 'systemReducer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getSystemGeneralAsync.fulfilled, ($state, action) => {
      $state.systemData = action.payload;
    });
    builder.addCase(getHeaderFooterAsync.fulfilled, ($state, action) => {
      $state.headerFooter = action.payload;
    });
    builder.addCase(getErrorPagesAsync.fulfilled, ($state, action) => {
      $state.errorPages = action.payload;
    });
  },
});

export default systemSlice.reducer;
