import { LOCAL_STORAGE } from "utils/constant";

let accessToken = window ? window.localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN) : null;
let refreshToken = window ? window.localStorage.getItem(LOCAL_STORAGE.REFRESH_TOKEN) : null;

/**
 * Listen for changes from other tabs
 */
window.addEventListener('storage', (event) => {
  if (event.key === 'token') {
    accessToken = event.newValue;
  }
});

export const getAccessToken = (): string | null => accessToken;

export const setAccessToken = (token: string): void => {
  accessToken = token;
  if (window) {
    window.localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, token);
  }
};
export const getRefreshToken = (): string | null => refreshToken;

export const setRefreshToken = (token: string): void => {
  refreshToken = token;
  if (window) {
    window.localStorage.setItem(LOCAL_STORAGE.REFRESH_TOKEN, token);
  }
};

export const removeAccessToken = (): void => {
  accessToken = null;
  if (window) {
    window.localStorage.removeItem(LOCAL_STORAGE.ACCESS_TOKEN);
  }
};

export const removeRefreshAccessToken = (): void => {
  accessToken = null;
  if (window) {
    window.localStorage.removeItem(LOCAL_STORAGE.REFRESH_TOKEN);
  }
};

export const setLocalStorage = (name: string, value: string) => {
  if (window) {
    window.localStorage.setItem(name, value);
  }
};

export const getLocalStorage = (name: string): string | null => window ? window.localStorage.getItem(name) : null;

export const clearLocalStorage = () => {
  if (window) {
    window.localStorage.clear();
  }
};

export const setSessionStorage = (name: string, value: string) => {
  if (window) {
    window.sessionStorage.setItem(name, value);
  }
};

export const getSessionStorage = (name: string) => window ? window.sessionStorage.getItem(name) : null;

export const removeSessionStorage = (name: string) => {
  if (window) {
    window.sessionStorage.removeItem(name);
  }
};
