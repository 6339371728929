import React, { Suspense } from "react";

import "App.scss";
import Loading from "components/atoms/Loading";
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { store } from "store";
import MainLayout from 'components/templates/MainLayout';
import InitSystem from 'hooks/initSystem';

import Home from "navigation/Home";
import Page from "navigation/Page";

const AppContent: React.FC = () => {
  InitSystem();

  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/" element={<Home />} />
        <Route path=":slug" element={<Page />} />
      </Route>
    </Routes>
  );
};
const App: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false
      }
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <BrowserRouter>
          <Suspense fallback={<Loading size="48" variant="fullScreen" />}>
            <AppContent />
          </Suspense>
        </BrowserRouter>
      </HelmetProvider>
    </QueryClientProvider>
  );
};

const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

export default AppWrapper;
