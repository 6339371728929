import { useEffect, useState } from 'react';

import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';

export default function useGA() {
  const { pathname } = useLocation();
  const [readyGA, setReadyGA] = useState(false);
  const gaIds = useAppSelector((state) => state.system.systemData?.gaIds);

  useEffect(() => {
    if (gaIds && gaIds.length > 0) {
      gaIds.forEach((ga) => ReactGA.initialize(ga));
      setReadyGA(true);
    }
  }, [gaIds, pathname]);

  useEffect(() => {
    if (readyGA) {
      ReactGA.pageview(pathname);
    }
  }, [readyGA, pathname])
}
