import DOMPurify from 'dompurify';
import React from 'react';

import mapModifiers from 'utils/functions';

export type Sizes =
  | '32x44'
  | '28x34'
  | '24x32'
  | '21x32'
  | '16x21'
  | '16x28'
  | '18x21'
  | '14x21';

export type TextStyle = (GeneralTextStyle | Sizes)[];

interface TextProps extends React.HtmlHTMLAttributes<HTMLParagraphElement>, WowOptionProps {
  modifiers?: TextStyle;
  type?: 'p' | 'span' | 'div';
  content?: string;
  customClass?: string;
}

const Text: React.FC<TextProps> = ({
  modifiers,
  type = 'p',
  content,
  customClass,
  children,
  ...props
}) => {
  const Element = type;
  return (
    <>
      {content ? (
        <Element
          className={`${mapModifiers('a-text', modifiers)} ${customClass || ''}`}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content, { ADD_TAGS: ['iframe'] }) }}
          data-wow-duration={props.dataWowDuration}
          data-wow-delay={props.dataWowDelay}
          data-wow-offset={props.dataWowOffset}
          data-wow-iteration={props.dataWowIteration}
          style={props.style}
        />
      ) : (
        <Element
          className={`${mapModifiers('a-text', modifiers)} ${customClass || ''}`}
          data-wow-duration={props.dataWowDuration}
          data-wow-delay={props.dataWowDelay}
          data-wow-offset={props.dataWowOffset}
          data-wow-iteration={props.dataWowIteration}
          style={props.style}
        >
          {children}
        </Element>
      )}
    </>
  );
};

Text.defaultProps = {
  modifiers: undefined,
  type: 'p',
  content: undefined,
};

export default Text;
