import { TemplateCodes } from 'navigation';
import Error from 'pages/Error';
import React, { FunctionComponent } from 'react';

export type RenderPageProps = {
  pageData?: PageResponseTypes<any>;
};

const RenderPage: React.FC<RenderPageProps> = ({ pageData }) => {
  if (!pageData) return <Error statusCode={404} />;

  const Component = TemplateCodes.find(
    (template) => template.templateCode === pageData?.templateCode,
  )?.component;

  if (Component) {
    return React.createElement<
      PageResponseTypes<any>>(Component as FunctionComponent, pageData);
  }

  return <Error statusCode={404} />;
};

export default RenderPage;
