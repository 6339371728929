import React from 'react';

const Home = React.lazy(() => import('pages/HomePage'));


export type TemplateCodeType = {
  templateCode?: string;
  component: React.FC<PageResponseTypes<any>>;
};

export const TEMPLATE_CODE_CONFIG = {
  HOME_PAGE: 'HOME_PAGE',
};

export const TemplateCodes: TemplateCodeType[] = [
  {
    templateCode: TEMPLATE_CODE_CONFIG.HOME_PAGE,
    component: Home,
  },
];
