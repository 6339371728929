import Image from 'components/atoms/Image';
import Link from 'components/atoms/Link';
import React, { useMemo } from 'react';

import Text from 'components/atoms/Text';
import { useAppSelector } from 'store/hooks';

interface FollowMeProps {
  isFooter?: boolean;
}

const FollowMe: React.FC<FollowMeProps> = ({ isFooter }) => {
  const footer = useAppSelector((state) => state.system.headerFooter?.footer);
  const socialMedia = useMemo(() => footer?.socials.map((social) => ({
    name: social.title,
    link: social.href,
    icon: social.urlLogo,
    alt: social.alt,
  })), [footer?.socials]);

  return (
    <div className="m-followMe">
      {
        !isFooter && (
          <Text modifiers={['700', '16x21', 'gray1']}>Follow me:</Text>
        )
      }
      <ul className="m-followMe_socials">
        {
          socialMedia?.map((item, index) => (
            <li
              key={index}
              className="m-followMe_link wow animate__rotateIn"
              data-wow-delay={isFooter ? '100ms' : '2s'}
            >
              <Link
                href={item.link}
                target="_blank"
              >
                <Image
                  src={item.icon} alt={item.alt}
                  ratio="1x1" size={{ width: '24', height: '24' }}
                  objectFit="contain"
                />
              </Link>
            </li>
          ))
        }
      </ul>
    </div>
  );
};
export default FollowMe;
