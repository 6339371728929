import { useEffect } from 'react';

import TagManager from 'react-gtm-module';
import { useAppSelector } from 'store/hooks';

export default function useGTM() {
  const gtmIds = useAppSelector((state) => state.system.systemData?.gtmIds);

  useEffect(() => {
    if (gtmIds && gtmIds.length > 0) {
      gtmIds.forEach((gtm) => TagManager.initialize({ gtmId: gtm }));
    }
  }, [gtmIds]);
}
