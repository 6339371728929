import React, { useMemo } from 'react';

import noImage132x74 from 'assets/images/dummy-132X74.webp';
import noImage264x148 from 'assets/images/dummy-264X148.webp';
import noImage396x222 from 'assets/images/dummy-396X222.webp';
import useDeviceQueries from 'hooks/useDeviceQueries';
import mapModifiers from 'utils/functions';

export type SizeImageType = 'cover' | 'contain' | 'inherit' | 'initial';

export interface ImageProps {
  src?: string;
  srcTablet?: string;
  srcMobile?: string;
  size?: {
    width: string;
    height: string;
  };
  sizeTablet?: {
    width: string;
    height: string;
  };
  sizeMobile?: {
    width: string;
    height: string;
  };
  alt?: string;
  ratio?: Ratio;
  objectFit?: SizeImageType;
  blur?: boolean
  loading?: 'lazy' | 'eager';
  className?: string;
}

const Image: React.FC<ImageProps> = ({
  src,
  srcMobile,
  srcTablet,
  size,
  sizeTablet,
  sizeMobile,
  alt,
  ratio,
  objectFit,
  loading,
  blur,
  className,
}) => {
  const { isMobile, isTablet } = useDeviceQueries();
  const sourceImage = useMemo(() => {
    if (isMobile) {
      return srcMobile || srcTablet || src || noImage132x74;
    }
    if (isTablet) {
      return srcTablet || src || noImage264x148;
    }
    return src || noImage396x222;
  }, [isMobile, isTablet, src, srcMobile, srcTablet]);

  const sizeImage = useMemo(() => {
    if (isMobile) {
      return {
        width: sizeMobile?.width || sizeTablet?.width || size?.width,
        height: sizeMobile?.height || sizeTablet?.height || size?.height,
      };
    }
    if (isTablet) {
      return {
        width: sizeTablet?.width || size?.width || sizeMobile?.width,
        height: sizeTablet?.height || size?.height || sizeMobile?.height,
      };
    }
    return {
      width: size?.width || sizeTablet?.width || sizeMobile?.width,
      height: size?.height || sizeTablet?.height || sizeMobile?.height,
    };
  }, [isMobile, isTablet, size?.height, size?.width,
    sizeMobile?.height, sizeMobile?.width,
    sizeTablet?.height, sizeTablet?.width]);

  return (
    <div className={`${mapModifiers('a-image', ratio, objectFit, blur && 'blur')} ${className}`}>
      <img src={sourceImage} alt={alt || 'without alt'} loading={loading} width={sizeImage.width} height={sizeImage.height} />
    </div>
  );
};

Image.defaultProps = {
  alt: '',
  srcMobile: undefined,
  srcTablet: undefined,
  objectFit: 'cover',
  loading: 'eager',
};

export default Image;
