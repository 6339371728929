import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import systemReducer from './system';
import menuReducer from './menu';

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  reducer: {
    system: systemReducer,
    menu: menuReducer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
