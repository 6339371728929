import { AxiosError } from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';

import usePreview from 'hooks/usePreview';
import Error from 'pages/Error';
import { getPageService } from 'services/page';
import RenderPage from './RenderPage';

const Page: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();

  const {
    data,
    error,
    isLoading
  } = usePreview<PageResponseTypes<any>>(
    async () => getPageService(slug || ''),
    ['getPage', slug],
    !!slug,
  );

  if (isLoading && slug) {
    return null;
  }

  if (error) {
    console.log('error page: ', error);
    const errorStt = (error as AxiosError)?.response?.status || 404;
    if (errorStt) {
      return (
        <Error statusCode={errorStt} />
      );
    }
    return null;
  }

  return <RenderPage pageData={data} />;
};

export default Page;
