import axiosInstance from 'services/instance';

export const getStaticHomePageService = async <T>(): Promise<PageResponseTypes<T>> => {
  const response = await axiosInstance.get('page/static/home-page');
  return response.data.data;
};

export const getPageService = async <T>(
  slug: string,
): Promise<PageResponseTypes<T>> => {
  const response = await axiosInstance.get(`page/${slug}`);
  return response.data.data;
}

export const getPreviewData = async <T,>(previewToken: string): Promise<T> => {
  const res = await axiosInstance.get(`page/preview/${previewToken}`);
  return res.data.data;
};
