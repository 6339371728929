import { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { getMenusAsync } from 'store/menu';
import { getErrorPagesAsync, getHeaderFooterAsync, getSystemGeneralAsync } from 'store/system';
import useGA from './useGA';
import useGTM from './useGTM';
import useFB from './useFB';

const InitSystem = () => {
  const dispatch = useAppDispatch();

  useGTM();
  useGA();
  useFB();

  useEffect(() => {
    dispatch(getSystemGeneralAsync());
    dispatch(getHeaderFooterAsync());
    dispatch(getMenusAsync());
    dispatch(getErrorPagesAsync());
  }, [dispatch]);
};
export default InitSystem;
