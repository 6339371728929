import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Image from 'components/atoms/Image';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { ErrorPagesType, ItemErrorPageType } from 'services/system/types';
import { useAppSelector } from 'store/hooks';
import { checkExternalUrl } from 'utils/functions';

import error404 from 'assets/images/error-404.png';
import error429 from 'assets/images/error-429.png';
import error500 from 'assets/images/error-500.png';
import Section from 'components/templates/Section';

export interface ErrorsProps {
  statusCode: number;
}
const Error: React.FC<ErrorsProps> = ({ statusCode }) => {
  const errorPages = useAppSelector((state) => state.system.errorPages);

  const errorBlockData = useMemo(
    () => {
      if (errorPages) {
        const error = errorPages[`error${statusCode}` as keyof ErrorPagesType];
        return {
          ...error,
          image: error?.image
        } as ItemErrorPageType;
      }
      if (statusCode === 429) {
        return {
          title: 'Too Many Requests',
          description: 'Please try again after a minute',
          image: error429
        } as ItemErrorPageType;
      }
      if (statusCode === 500) {
        return {
          title: 'Internal Server Error',
          description: 'Sorry for this problem. Please contact us if this situation persists',
          linkContact: {
            target: '_blank',
            text: 'Liên hệ',
            url: 'https://www.facebook.com/hnhatuan.16',
          },
          linkHome: {
            target: '_self',
            text: 'Trang chủ',
            url: '/',
          },
          image: error500
        } as ItemErrorPageType;
      }
      return {
        title: 'Not Found',
        description: 'Please check the URL in the address bar and try again.',
        linkContact: {
          target: '_blank',
          text: 'Liên hệ',
          url: 'https://www.facebook.com/hnhatuan.16',
        },
        linkHome: {
          target: '_self',
          text: 'Trang chủ',
          url: '/',
        },
        image: error404
      } as ItemErrorPageType;
    },
    [statusCode, errorPages],
  );

  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="render:status_code" content={statusCode?.toString()} />
      </Helmet>
      <Section>
        <div className="container text-center">
          <div style={{ maxWidth: '340px', margin: 'auto' }}>
            <Image alt={errorBlockData?.title} objectFit="contain" ratio="1x1" src={errorBlockData?.image} />
          </div>
          <div>
            <Heading type="h2" modifiers={['center']} style={{ marginTop: '16px' }} content={errorBlockData?.title} />
            <Text modifiers={['center']} content={errorBlockData?.description} style={{ marginTop: '16px' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px', paddingBottom: '20px' }}>
            {
              errorBlockData.linkHome && (
                <Link
                  href={errorBlockData.linkHome?.url || '/'}
                  target={errorBlockData.linkHome?.target}
                  useExternal={checkExternalUrl(errorBlockData.linkContact?.url)}
                >
                  <Button>{errorBlockData.linkHome?.text}</Button>
                </Link>
              )
            }
            {
              errorBlockData.linkContact && (
                <Link
                  href={errorBlockData.linkContact?.url || '/'}
                  target={errorBlockData.linkContact?.target}
                  useExternal={checkExternalUrl(errorBlockData.linkContact?.url)}
                >
                  <Button>{errorBlockData.linkContact?.text}</Button>
                </Link>
              )
            }
          </div>
        </div>
      </Section>
    </div>
  );
};
export default Error;
