import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import React, { useMemo, useState } from 'react';

import Link from 'components/atoms/Link';
import Container from 'components/organisms/Container';
import Navigation from 'components/organisms/Navigation';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { checkExternalUrl, scrollDownNextSectionById } from 'utils/functions';
import { returnRouteMenu } from 'utils/menu';

interface HeaderProps {
}

const Header: React.FC<HeaderProps> = () => {
  const [indexMenuOpen, setIndexMenuOpen] = useState<number>();
  const { hash } = useLocation();
  const header = useAppSelector((state) => state.system.headerFooter?.header);
  const menu = useAppSelector((state) => state.menu);

  const menuHeader = useMemo(() => menu.data?.find((item) => item.code === header?.codeMenu)?.items, [menu, header?.codeMenu]);

  return (
    <header className="t-header">
      <Container>
        <div className="t-header_wrap">
          <div className="t-header_logo">
            <Link href="/">
              <h1 style={{ fontSize: 16, margin: 0 }}>
                <Image loading="eager" alt="Portfolio" src={header?.logo} ratio="1x1" objectFit="contain" size={{ width: '60', height: '60' }} />
              </h1>
            </Link>
          </div>
          {menuHeader && menuHeader.length > 0 && (
            <ul className="t-header_menu">
              {
                menuHeader.map((item, idx) => {
                  const isOpen = indexMenuOpen === item.id;
                  return (
                    <li
                      key={`mainMenu-${idx.toString()}`}
                      className={`t-header_menu_navItem ${item.children?.length ? 't-header_menu_hasChild' : ''} ${item.children?.length && isOpen ? 't-header_menu_hasChild-show' : ''}`}
                      onClick={() => setIndexMenuOpen(isOpen ? undefined : item.id)}
                    >
                      <div className="t-header_menu_wrapLink">
                        <Link
                          href={returnRouteMenu(item, 'vi')}
                          customClass="t-header_menu_navLink"
                          target={item.target}
                          activeClassName={hash === item.link ? 'active' : ''}
                          useExternal={item?.slug ? false
                            : checkExternalUrl(item.link)}
                        >
                          {item.name}
                        </Link>
                      </div>
                    </li>
                  );
                })
              }
            </ul>
          )}
          <Link
            customClass="t-header_button" href="#form"
            handleClick={() => scrollDownNextSectionById("#form")}
          >
            <Button modifiers={['uppercase', 'outline']}>
              <Text modifiers={['700', '16x21']}>{`LET'S TALK`}</Text>
            </Button>
          </Link>
        </div>
      </Container>
      <Navigation menus={menuHeader} />
    </header>
  );
};

export default Header;
