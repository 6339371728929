import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { getPreviewData } from 'services/page';

const usePreview = <T>(
  callService: (...arg: any) => Promise<T>,
  dependency?: any,
  enableService?: boolean,
) => {
  const location = useLocation();
  const previewParams = useMemo(
    () => new URLSearchParams(location.search).get('preview'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  );

  const { data, isLoading, error } = useQuery<T>(
    dependency,
    () => (previewParams ? getPreviewData(previewParams) : callService()),
    {
      enabled: enableService,
    }
  );

  return {
    data,
    isLoading,
    error,
    isPreview: !!previewParams,
  };
};

export default usePreview;
