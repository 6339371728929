import axiosInstance from 'services/instance';
import { HeaderFooter, SystemTypes } from './types';

export const getSystemService = async (): Promise<SystemTypes> => {
  const response = await axiosInstance.get('system');
  return response.data.data;
};

export const getHeaderFooterService = async (): Promise<HeaderFooter> => {
  const response = await axiosInstance.get('header-footer');
  return response.data.data;
};

export const getErrorPagesService = async (): Promise<any> => {
  const response = await axiosInstance.get('errors-page');
  return response.data.data;
};
