import DOMPurify from 'dompurify';
import React from 'react';

import mapModifiers from 'utils/functions';

export type Sizes =
  | '48x62'
  | '32x42'
  | '36x47'
  | '28x34'
  | '24x31'
  | '21x28';

export type TextStyle = (GeneralTextStyle | Sizes)[];

export interface HeadingProps extends React.HtmlHTMLAttributes<HTMLParagraphElement>, WowOptionProps {
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  content?: string;
  modifiers?: TextStyle;
  children?: React.ReactNode;
  customClass?: string;
}

const Heading: React.FC<HeadingProps> = ({
  children,
  type = 'h2',
  content,
  modifiers,
  customClass,
  ...props
}) => {
  const Element = type;
  return (
    <>
      {
        content ? (
          <Element
            className={`${mapModifiers('a-heading', modifiers)} ${customClass || ''}`}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
            data-wow-duration={props.dataWowDuration}
            data-wow-delay={props.dataWowDelay}
            data-wow-offset={props.dataWowOffset}
            data-wow-iteration={props.dataWowIteration}
            style={props.style}
          />
        ) : (
          <Element
            className={`${mapModifiers('a-heading', modifiers)} ${customClass || ''}`}
            data-wow-duration={props.dataWowDuration}
            data-wow-delay={props.dataWowDelay}
            data-wow-offset={props.dataWowOffset}
            data-wow-iteration={props.dataWowIteration}
            style={props.style}
          >
            {children}
          </Element>
        )
      }
    </>
  );
};

Heading.defaultProps = {
  type: 'h2',
  modifiers: undefined,
  content: undefined,
};

export default Heading;
