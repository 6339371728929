import 'wowjs/css/libs/animate.css';

import React, { useMemo } from 'react';

import { Outlet } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header';

interface MyCustomCSS extends React.CSSProperties {
  '--theme': string;
}

interface MainLayoutProps {
}

const MainLayout: React.FC<MainLayoutProps> = () => {
  const styleSystem = useMemo(() => ({
    "--theme": "#0074D8",
    "--text": "#1c2650" //#1c2650, #0074D8
  } as MyCustomCSS), []);

  return (
    <div style={styleSystem}>
      <Header />
      <main className="t-mainLayout">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default MainLayout;
