/* eslint-disable no-unused-vars */
import i18n from 'i18n';

// Recursive Menu
export const groupMenus = (menus?: MenuItemTypes[]) => {
  if (!menus) {
    return [];
  }
  const recursiveMenus = (
    menuList: MenuItemTypes[],
    parentId: number,
  ): MenuItemTypes[] => {
    const menusGrouped: MenuItemTypes[] = [];
    menuList?.forEach((menu) => {
      if (menu.parentId === parentId) {
        const children = recursiveMenus(menuList, menu.id);
        menusGrouped.push(
          children.length > 0
            ? {
              ...menu,
              children,
            }
            : {
              ...menu,
            },
        );
      }
    });
    return menusGrouped;
  };
  if (menus.length > 0) {
    const firstLevelParentId = menus.find((menu) => menu.depth === 1)!.parentId;
    return recursiveMenus(menus, firstLevelParentId);
  }
  return [];
};

export function getLangURL(lang?: string) {
  if (lang && lang !== 'vi') return `/${lang}`;
  return '';
}

export function getLangSlug(lang?: string) {
  if (lang && lang !== 'vi') return `${lang}/`;
  return '';
}

export const returnRouteMenu = (menu: MenuItemTypes, lang?: string) => {
  if (menu.type === 'customLink' && menu.link) {
    return `${getLangURL(lang)}${menu.link}`;
  }
  if (menu?.slug) {
    return `${getLangURL(lang)}/${menu?.slug !== '/' ? menu.slug : ''}`;
  }
  return '';
};

export const detectTypePrefix = (type: string, lang: string) => {
  switch (type) {
    // Pages
    default:
      return '';
  }
};

const checkTypePrefix = (
  type: string,
  slugParam?: string,
  linkParam?: string,
  cssClass?: string,
) => {
  if (slugParam) return `${getLangSlug(i18n.language)}${slugParam}/`;
  if (linkParam) return `${getLangSlug(i18n.language)}${linkParam}/`;
  return '';
};

const checkTypePrefixParentCustomLink = (menu: MenuItemTypes) => {
  if (menu.type === 'customLink') {
    if (menu?.cssClass === 'menu-lvdt') {
      return `${getLangURL(i18n.language)}/${detectTypePrefix(menu?.type, i18n.language)}`;
    }
    return menu.link || '';
  }
  if (menu.type === 'OneContent\\News\\Models\\NewsCategory') {
    return `${getLangURL(i18n.language)}/${detectTypePrefix(menu?.type, i18n.language)}`;
  }
  return menu.link || '';
};

export const prefixGroupMenu = (menus?: MenuItemTypes[]) => {
  if (!menus) {
    return [];
  }

  return menus.map((ele) => {
    const prefix = checkTypePrefix(ele.type, ele?.slug, ele.link, ele.cssClass);

    return {
      ...ele,
      link: checkTypePrefixParentCustomLink(ele),
      slug: `${ele?.slug || ''}`,
      isActivated: !!((ele?.slug || !!(ele.type === 'customLink' && ele.link))),
      children: ele.children
        ? ele.children.map((subEle) => ({
          ...subEle,
          slug: subEle.type === 'OneContent\\Page\\Models\\Page' ? subEle.slug : `${prefix}${subEle?.slug || ''}`,
        }))
        : undefined,
    };
  }) as MenuItemTypes[];
};
