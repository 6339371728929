import React from 'react';

import mapModifiers from 'utils/functions';

export interface ContainerProps {
  fullScreen?: boolean;
  noPaddingRight?: boolean;
  noPaddingLeft?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const Container: React.FC<ContainerProps> = ({
  children, fullScreen, noPaddingRight, noPaddingLeft, className = ''
}) => (
  <div className={`container ${mapModifiers('o-container',
    fullScreen && 'fullscreen',
    noPaddingRight && 'noPaddingRight',
    noPaddingLeft && 'noPaddingLeft')} ${className}`}
  >
    {children}
  </div>
);

Container.defaultProps = {
  fullScreen: false,
  noPaddingRight: false,
  noPaddingLeft: false,
};

export default Container;
