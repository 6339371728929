import React, { useEffect, useRef } from 'react';

import Image from 'components/atoms/Image';
import Link from 'components/atoms/Link';
import Text from 'components/atoms/Text';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useLocation } from 'react-router-dom';
import mapModifiers from 'utils/functions';


interface NavigationProps {
  menus?: Array<MenuItemTypes>;
}

const Navigation: React.FC<NavigationProps> = ({ menus }) => {
  const { hash } = useLocation();
  const indicatorRef = useRef<HTMLSpanElement>(null);
  const { width } = useWindowDimensions();

  useEffect(() => {
    const activeMenu = () => {
      let items = document.querySelectorAll('.o-navigation_item');
      const widthItem = (width - 12) / items.length;

      items?.forEach((a, i) => {
        if (a.classList.contains('o-navigation_item-active') && indicatorRef.current) {
          indicatorRef.current.style.width = `${widthItem}px`;
          if (i === 0) {
            indicatorRef.current.style.left = `calc(${`0%`})`;
          } else if (i === items.length - 1) {
            indicatorRef.current.style.left = `calc((100% - ${widthItem}px))`;
          } else {
            indicatorRef.current.style.left = `calc(0% + ${widthItem * i}px)`;
          }
        }
      });
    };
    if (indicatorRef.current && hash && menus && menus?.length > 0) {
      activeMenu();
    }
  }, [hash, indicatorRef, width, menus]);

  return (
    <>
      <div className="o-navigation">
        {
          menus?.find((item) => item.link === hash) && (
            <span ref={indicatorRef} className="nav-indicator"></span>
          )
        }
        <ul>
          {
            menus?.map((item, index) => (
              <li key={index} style={{ width: `calc(100% / ${menus.length})` }} className={mapModifiers('o-navigation_item', hash === item.link ? 'active' : '')}>
                <Link href={item.link}>
                  <div className="o-navigation_item-icon">
                    <Image
                      alt={item.name}
                      size={{
                        height: '24',
                        width: '24',
                      }}
                      ratio="1x1"
                      objectFit="contain"
                      src={hash === item.link ? item.iconActive : item.iconDefault}
                    />
                  </div>
                  <Text
                    modifiers={[hash === item.link ? '700' : '500', 'gray']}
                    customClass="o-navigation_item-title"
                  >
                    {item.name}
                  </Text>
                </Link>
              </li>
            ))
          }
        </ul>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="filter-svg">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
            <feBlend in="SourceGraphic" in2="goo" />
          </filter>
        </defs>
      </svg>
    </>
  );
};

export default Navigation;
