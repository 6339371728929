import React from 'react';
import mapModifiers from 'utils/functions';

interface SectionProps {
  children?: React.ReactNode;
  idRef?: string;
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
}

const Section: React.FC<SectionProps> = ({ children, idRef, noPaddingTop, noPaddingBottom }) => (
  <section
    id={idRef}
    className={mapModifiers('t-section', noPaddingTop && 'noPaddingTop', noPaddingBottom && 'noPaddingBottom')}
  >
    {children}
  </section>
);

export default Section;
