import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getMenusService } from 'services/menu';

interface MenuState {
  data: MenusResponseTypes[]
}

const initialState: MenuState = {
  data: []
};

export const getMenusAsync = createAsyncThunk<MenusResponseTypes[]>(
  'menuReducer/getMenusAsync',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getMenusService();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const menuSlice = createSlice({
  name: 'menuReducer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getMenusAsync.fulfilled, ($state, action) => {
      $state.data = action.payload;
    });
  },
});

export default menuSlice.reducer;
